import { useMutation, useQueryClient } from '@tanstack/react-query';
import escape from 'lodash/escape';
import z from 'zod';

import {
  TranslatedString,
  t,
  translatedStringSchema,
} from '@peakon/shared/features/i18next/t';
import {
  AccessEnum,
  StandardEnum,
  StatusEnum,
  TypeEnum,
} from '@peakon/shared/schemas/api/attributes';
import api from '@peakon/shared/utils/api';
import { validateData } from '@peakon/shared/utils/validateData/validateData';

import { getAttributesQueryKeys } from './useGetAttributesQuery';
import {
  catchHandler,
  showSuccessNotification,
} from '../../../../../actions/NotificationActions';
import { useAppDispatch } from '../../../../../utils/reduxHooks';

type UpdateAttributeParams = {
  id: string;
  isActive: boolean;
};

const responseSchema = z.object({
  data: z.object({
    id: z.string(),
    attributes: z.object({
      aliases: z.array(z.string()),
      comparisonAccess: AccessEnum,
      comparisonAccessLocked: z.boolean(),
      description: z.string().nullable(),
      descriptionTranslated: translatedStringSchema.nullable(),
      disableSegmentation: z.boolean().optional(),
      employeeAccess: AccessEnum,
      employeeAccessLocked: z.boolean(),
      name: z.string(),
      nameTranslated: translatedStringSchema,
      open: z.boolean(),
      primary: z.boolean(),
      rejectExternal: z.boolean().nullable(),
      sensitive: z.boolean(),
      standard: StandardEnum.nullable(),
      status: StatusEnum,
      trueBenchmark: z.boolean(),
      type: TypeEnum,
    }),
    links: z.object({
      self: z.string(),
    }),
    type: z.literal('attributes'),
  }),
});

const updateAttribute = async ({ id, isActive }: UpdateAttributeParams) => {
  const body = {
    data: {
      id,
      type: 'attributes',
      attributes: {
        trueBenchmark: isActive,
      },
    },
  };

  const response = await api.patch(`/attributes/${id}`, null, body);
  return validateData(response, responseSchema, {
    errorMessagePrefix: 'updateTrueBenchmarkAttribute-responseSchema',
  }).data;
};

const useTranslationMap = () => {
  const trueBenchmarksTranslationMap = {
    enabled: (nameTranslated: TranslatedString) =>
      t('true_benchmarks__attribute_enabled', {
        replace: { attribute: escape(nameTranslated) },
      }),
    disabled: (nameTranslated: TranslatedString) =>
      t('true_benchmarks__attribute_disabled', {
        replace: { attribute: escape(nameTranslated) },
      }),
  } as const;

  return { trueBenchmarksTranslationMap };
};

export const useUpdateAttributeMutation = () => {
  const { trueBenchmarksTranslationMap } = useTranslationMap();
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();

  return useMutation({
    mutationFn: updateAttribute,
    onSuccess: ({ attributes: { nameTranslated, trueBenchmark } }) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Automatically disabled here to enable the rule globally
      queryClient.invalidateQueries({
        queryKey: getAttributesQueryKeys.getAttributes(),
        exact: true,
      });

      dispatch(
        showSuccessNotification({
          message: trueBenchmark
            ? trueBenchmarksTranslationMap.enabled(nameTranslated)
            : trueBenchmarksTranslationMap.disabled(nameTranslated),
        }),
      );
    },
    onError: (error: Error) => {
      dispatch(catchHandler(error));
    },
  });
};
