import { useQuery } from '@tanstack/react-query';
import sortBy from 'lodash/sortBy';
import z from 'zod';

import jsonapiparser from '@peakon/jsonapiparser';
import { translatedStringSchema } from '@peakon/shared/features/i18next/t';
import api from '@peakon/shared/utils/api';
import { validateData } from '@peakon/shared/utils/validateData/validateData';

import { catchHandler } from '../../../../../actions/NotificationActions';
import { useAppDispatch } from '../../../../../utils/reduxHooks';

const TRUE_BENCHMARKS_ATTRIBUTES = [
  'age',
  'department',
  'gender',
  'level',
  'tenure',
  'office',
];

const GET_ATTRIBUTES_PARAMS = {
  filter: {
    status: 'active',
    comparisonAccess: 'unrestricted',
  },
  fields: {
    attributes: 'name,nameTranslated,standard,trueBenchmark',
  },
};

const attributeAttributesSchema = z.object({
  name: z.string(),
  nameTranslated: translatedStringSchema,
  trueBenchmark: z.boolean(),
  standard: z.string().nullable(),
});

const attributeSchema = z.object({
  id: z.string(),
  attributes: attributeAttributesSchema,
  type: z.literal('attributes'),
  links: z.object({
    self: z.string(),
  }),
});

const nonNullStandardAttributeSchema = attributeSchema.extend({
  attributes: attributeAttributesSchema.extend({
    standard: z.string(),
  }),
});

type NonNullStandardAttribute = z.infer<typeof nonNullStandardAttributeSchema>;

const responseSchema = z.object({
  data: z.array(attributeSchema),
});

export const getAttributesQueryKeys = {
  getAttributes: () => ['data_settings__true_benchmark'] as const,
};

const getAttributes = async () => {
  const response = await api.get('/attributes', GET_ATTRIBUTES_PARAMS);
  return validateData(jsonapiparser(response), responseSchema, {
    errorMessagePrefix: 'getTrueBenchmarkAttributes-responseSchema',
  }).data;
};

export const useGetAttributesQuery = () => {
  const dispatch = useAppDispatch();

  return useQuery({
    queryKey: getAttributesQueryKeys.getAttributes(),
    queryFn: getAttributes,
    placeholderData: [],
    select: (data) => {
      if (!data) {
        return [];
      }

      return sortBy(
        data.filter(
          (attribute): attribute is NonNullStandardAttribute =>
            attribute.attributes.standard !== null &&
            TRUE_BENCHMARKS_ATTRIBUTES.includes(attribute.attributes.standard),
        ),
        'attributes.name',
      );
    },
    onError: (error: Error) => {
      dispatch(catchHandler(error));
    },
  });
};
