import { useMutation, useQueryClient } from '@tanstack/react-query';
import z from 'zod';

import jsonapiparser from '@peakon/jsonapiparser';
import api from '@peakon/shared/utils/api';
import { validateData } from '@peakon/shared/utils/validateData/validateData';

import { queryKeys } from './queryKeys';

const parsedResponseSchema = z.object({
  data: z.object({
    id: z.string(),
    /*
     * Note: the following properties exist on the response but are not validated since we are only returning the id from the mutation function
     * attributes: z.object({}),
     * links: z.object({}),
     * relationships: z.object({}),
     * type: z.literal('segments'),
     */
  }),
});

const updateSegment = async (segmentId: string) => {
  const response = await api.patch(`/segments/${segmentId}`, null, {
    data: {
      type: 'segments',
      attributes: {
        benchmarkId: null,
        benchmarkType: null,
      },
    },
  });

  return validateData(jsonapiparser(response), parsedResponseSchema, {
    errorMessagePrefix: 'updateSegment-parsedResponseSchema',
  }).data.id;
};

export const useUpdateSegmentMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateSegment,
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: queryKeys.segments() }),
  });
};
